import { ArrowForward } from '../../../Components/Atoms/Icons';
import React, { useState } from 'react';
import Box from '../../../Components/Atoms/Box';
import Button from '../../../Components/Atoms/Button';
import Checkbox from '../../../Components/Atoms/Checkbox';
import TextField from '../../../Components/Atoms/TextField';
import ShowPwdTextfield from '../../../Components/Molecules/ShowPwdTextfield.tsx';
import './styles.scss';

export type EmailFormProps = {
  invitationId: string;
  goBack: () => void;
  handleSubmit: ({
    email,
    password,
    name,
  }: {
    email: any;
    password: any;
    name?: string | undefined;
  }) => Promise<void>;
  error: string | null;
  setemailMethod: React.Dispatch<React.SetStateAction<'signUp' | 'signIn'>>;
  emailMethod: 'signUp' | 'signIn';
};

const EmailForm = ({
  invitationId,
  goBack,
  handleSubmit,
  error,
  setemailMethod,
  emailMethod,
}: EmailFormProps) => {
  const [showPwd, setshowPwd] = useState(false);
  const [state, setState] = useState<{
    errors: Record<string, string | null>;
    informations: Record<string, string>;
  }>({
    errors: {},
    informations: { email: '', password: '', name: '' },
  });

  const onBlur = (target: { name: string; value: string }) => {
    const { name, value } = target;

    if (typeof value === undefined) {
      throw new Error('Value is undefined');
    }

    let error: any = null;

    if (name === 'email') {
      let regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

      if (!value.match(regex)) {
        error = 'Please enter a valid email';
      }
    } else if (name === 'password') {
      if (value.length < 8) {
        error = 'Password should have at least 8 characters';
      }
    } else if (name === 'name') {
      if (value.length < 3) {
        error = 'Please enter your full name';
      }
    }

    setState((st) => ({
      ...st,
      errors: { ...st.errors, [target.name]: error },
    }));

    return !!error;
  };

  const onSubmit = (e: any) => {
    console.log('in submit');
    e.preventDefault();

    let hasErrors: any = [];

    ['name', 'email', 'password'].forEach((info: string) => {
      let hasError = onBlur({
        name: info,
        value:
          typeof state.informations[info] === 'string'
            ? state.informations[info]
            : '',
      });

      if (hasError) {
        hasErrors.push(info);
      }
    });

    if (hasErrors.length) return;

    let infoToSubmit: {
      email: any;
      password: any;
      name?: string | undefined;
    } = {
      email: state.informations.email,
      password: state.informations.password,
      name: state.informations.name,
    };

    handleSubmit(infoToSubmit);
  };

  const onChange = (target: { name: string; value: string }) => {
    setState((st) => ({
      ...st,
      informations: { ...st.informations, [target.name]: target.value },
    }));
  };

  return (
    <Box
      component='form'
      id='emailForm-component'
      className='flex-column'
      onSubmit={onSubmit}
    >
      <TextField
        fullWidth
        className='form-fields'
        name='name'
        label='Enter name'
        labelTranslation='helpersAndErrors'
        value={
          state.informations.name
            ? state.informations.name
            : ''
        }
        onChange={(
          e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        ) => onChange({ name: e.target.name, value: e.target.value })}
        onBlur={(
          e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
        ) => onBlur({ name: e.target.name, value: e.target.value })}
        error={!!state.errors.name}
        helperText={
          typeof state.errors.name === 'string' ? state.errors.name : ''
        }
      />
      <TextField
        fullWidth
        className='form-fields'
        name='email'
        label='Enter email'
        value={state.informations.email ? state.informations.email : ''}
        onChange={(
          e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        ) => onChange({ name: e.target.name, value: e.target.value })}
        onBlur={(
          e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
        ) => onBlur({ name: e.target.name, value: e.target.value })}
        error={!!state.errors.email}
        helperText={
          typeof state.errors.email === 'string' ? state.errors.email : ''
        }
      />

      <ShowPwdTextfield
        state={state}
        onBlur={onBlur}
        setshowPwd={setshowPwd}
        onChange={onChange}
        showPwd={showPwd}
      />

      <Checkbox
        translation='common'
        className='form-checkbox'
        color='primary'
        checked={emailMethod === 'signIn'}
        onChange={() => {
          setemailMethod(emailMethod === 'signUp' ? 'signIn' : 'signUp');
        }}
        label='I already have a Gastronaut account (for another restaurant)'
      />

      <Button
        translation='common'
        fullWidth
        endIcon={() => <ArrowForward />}
        variant='primary'
        type='submit'
      >
        {emailMethod === 'signUp' ? 'Create account' : 'Sign in'}
      </Button>
    </Box>
  );
};

export default EmailForm;
