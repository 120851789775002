import React from 'react';
import {
  default as MaterialInput,
  InputProps as MaterialInputProps,
} from '@material-ui/core/Input';
import { Translations } from '../../../utils/types';
import './styles.scss';

type NewProps = {
  helperText?: string;
  helperTextTranslation?: Translations;
};

export type InputProps = MaterialInputProps & NewProps;

const Input: React.FC<InputProps> = ({ ...props }) => {
  return (
    <>
      <MaterialInput {...props} />
    </>
  );
};

export default Input;
