import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../Contexts/AuthContext';
import { ArrowForward } from '@material-ui/icons';
import NavigationBar from '../../Components/NavigationBar/NavigationBar';
import Button from '../../../Components/Atoms/Button';
import TextField from '../../../Components/Atoms/TextField';
import Card from '../../../Components/Molecules/Card';
import Box from '../../../Components/Atoms/Box';

import './styles.scss';

type TargetType = { name: string; value: string };

const SendRequest = () => {
  const { invitationId } = useParams();
  const { invitationState, sendInvitation, signOutUser } = useContext(
    AuthContext
  );
  const [state, setstate] = useState<{
    name: string | null | undefined;
    errors: Record<string, string | null>;
  }>({
    name: invitationState.displayName,
    errors: {},
  });
  const navigate: any = useNavigate();

  console.log('invstateSR', invitationState);
  console.log('stateSR', state);

  if (invitationState.error) {
    // console.log(invitationState);
    navigate(`/${invitationId}/error`);
  }

  const onSubmit = async (e: any) => {
    e.preventDefault();

    if (state.name) {
      let hasError = onBlur({ name: 'name', value: state.name });

      if (hasError) return;

      const { error }: any = await sendInvitation(state.name);

      if (!error) {
        navigate(`/${invitationId}/waitingForAcceptance`);
      }

      setstate((st) => ({
        ...st,
        errors: { ...st.errors, name: error },
      }));

      return;
    } else {
      console.log(
        'onSubmit in sendRequest has state.name as null or undefined'
      );
    }
  };

  const onChange = (target: TargetType) =>
    setstate((st) => ({ ...st, name: target.value }));

  const onBlur = (target: TargetType) => {
    let error: any = null;

    console.log('tar', target);

    if (target.name === 'name') {
      if (target.value.length < 3) {
        error = 'Please enter your full name';
      }
    }

    setstate((st) => ({ ...st, errors: { ...st.errors, name: error } }));

    return !!error;
  };

  useEffect(() => {
    if (invitationState.new) {
      navigate(`/${invitationId}`);
    }
  }, []);

  return (
    <Box flex>
      <NavigationBar />
      <div id='send-request-screen' className='flex-column'>
        <Card
          titleAlignment='center'
          className='card'
          title='Send request'
          typoVariantTitle='h2'
          typoVariantDescription='text-2'
          titleTranslation='common'
        >
          Please confirm your name. It will be shown on the request to the
          restaurant manager.
        </Card>

        <Box component='form' onSubmit={onSubmit}>
          <TextField
            fullWidth
            name='name'
            value={state.name ? state.name : ''}
            className='textfield'
            error={!!state.errors.name}
            helperText={state.errors.name ? state.errors.name : ''}
            onChange={(
              e: React.FocusEvent<
                HTMLInputElement | HTMLTextAreaElement,
                Element
              >
            ) => onChange({ name: e.target.name, value: e.target.value })}
            onBlur={(
              e: React.FocusEvent<
                HTMLInputElement | HTMLTextAreaElement,
                Element
              >
            ) => onBlur({ name: e.target.name, value: e.target.value })}
            label='Enter name'
            labelTranslation='helpersAndErrors'
          />

          <Button
            fullWidth
            type='submit'
            variant='primary'
            translation='common'
            endIcon={() => <ArrowForward />}
          >
            Send request
          </Button>
        </Box>
      </div>

      <Box
        className='flex-grow'
        hideOn={['md']}
        style={{
          height: 'calc(100vh - -65px)',
          maxWidth: '40%',
          backgroundImage: 'url("/rest-illustration.png")',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      />
    </Box>
  );
};

export default SendRequest;
