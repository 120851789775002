import React, { createContext, useState, useEffect } from 'react';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';

import '../styles/general.scss';
import '../styles/colors.scss';
import '../styles/typography.scss';

import { ThemeProvider, createTheme } from '@material-ui/core/styles';
// import { light, dark } from '@material-ui/core/styles/createPalette';

type ThemeContextValue = {
  darkMode: boolean;
  setdarkMode: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ThemeContext = createContext<ThemeContextValue>({
  darkMode: false,
  setdarkMode: () => {},
});

function darkmodeEnabled() {
  let preferesDarkMode = true;

  if (window.matchMedia) {
    preferesDarkMode = window.matchMedia('(prefers-color-scheme: dark)')
      .matches;
  }

  if (window.localStorage && window.localStorage.getItem('darkMode') !== null) {
    preferesDarkMode = window.localStorage.getItem('darkMode') !== 'false';
  }

  return preferesDarkMode;
}

const palette: { darkMode: PaletteOptions; lightMode: PaletteOptions } = {
  darkMode: {
    type: 'dark',
    primary: {
      main: '#0bdbc5',
      contrastText: '#121212',
      light: 'rgba(11,219,197,0.6)',
    },
    secondary: {
      main: '#f50057',
      contrastText: '#ffffff',
    },
    text: {
      primary: 'rgba(255, 255, 255, 0.87)',
      secondary: 'rgba(255, 255, 255, 0.54)',
      disabled: 'rgba(255, 255, 255, 0.38)',
      hint: 'rgba(255, 255, 255, 0.38)',
    },
    divider: 'rgba(255,255,255, 0.2)',
  },
  lightMode: {
    type: 'light',
    primary: {
      main: '#10CCB9',
      contrastText: '#252627',
      light: 'rgba(11,219,197,0.6)',
    },
    secondary: {
      main: '#f50057',
      contrastText: '#ffffff',
    },
    text: {
      primary: 'rgba(37, 38, 39, 0.87)',
      secondary: 'rgba(37, 38, 39, 0.54)',
      disabled: 'rgba(37, 38, 39, 0.38)',
      hint: 'rgba(37, 38, 39, 0.38)',
    },
  },
};

const ThemeContextProvider = ({ children }: any) => {
  const [darkMode, setdarkMode] = useState(darkmodeEnabled());

  useEffect(() => {
    if (window.localStorage) {
      window.localStorage.setItem('darkMode', String(darkMode));
      document.getElementsByTagName('body')[0].classList.add('darkMode');
    }

    if (darkMode) {
      document.getElementsByTagName('body')[0].classList.remove('lightMode');
      document.getElementsByTagName('body')[0].classList.add('darkMode');
    } else {
      document.getElementsByTagName('body')[0].classList.remove('darkMode');
      document.getElementsByTagName('body')[0].classList.add('lightMode');
    }
  }, [darkMode]);

  const theme = createTheme({
    palette: darkMode ? palette.darkMode : palette.lightMode,
    typography: {
      h1: {
        fontSize: '2.5rem',
      },
      h2: {
        fontSize: '2rem',
      },
      subtitle1: {
        fontSize: '1.5rem',
        lineHeight: '2rem',
      },
      subtitle2: {
        fontSize: '1.25rem',
      },
    },
  });

  return (
    <ThemeContext.Provider value={{ darkMode, setdarkMode }}>
      <div className='App'>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </div>
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
