import React, { forwardRef } from 'react';
import { classHelper, sizeHelper } from '../../../utils/helper';
import { Size, Modify } from '../../../utils/types';
import Grid from '@material-ui/core/Grid';
import './styles.scss';

const Component = forwardRef(({
  component = 'div',
  ...props
}: Modify<
  React.HTMLAttributes<HTMLLabelElement | HTMLDivElement | HTMLFormElement>,
  { component: 'div' | 'label' | 'form' }
>) => {
  if (component === 'label') {
    return <label {...props} />;
  } else if (component === 'form') {
    return <form {...props} />;
  } else {
    return <div {...props} />;
  }
});

export interface BoxProps extends Omit<React.HTMLAttributes<HTMLLabelElement | HTMLDivElement | HTMLFormElement>, "title" | "style"> {
  className?: string;
  elevation?: null | number;
  component?: 'div' | 'label' | 'form';
  width?: any;
  style?:React.CSSProperties;
  flex?: boolean;
  inlineFlex?: boolean;
  margin?: Size;
  padding?: Size;
  outline?: boolean;
  fullSize?: boolean;
  background?: boolean;
  underline?: boolean;
  hideOn?: ('sm' | 'md' | 'lg')[];
  children?: React.ReactNode;
  xs?:
    | boolean
    | 'auto'
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | undefined;
  sm?:
    | boolean
    | 'auto'
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | undefined;
  md?:
    | boolean
    | 'auto'
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | undefined;
  lg?:
    | boolean
    | 'auto'
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | undefined;
};
  

const Box = ({
  className = '',
  style = {},
  elevation,
  hideOn = [],
  flex = false,
  inlineFlex = false,
  margin = 0,
  padding = 0,
  outline = false,
  children,
  fullSize = false,
  underline = false,
  background = false,
  width,
  component = 'div',
  xs,
  sm,
  md,
  lg,
  ...props
}: BoxProps) => {
  const classNames = classHelper([
    outline ? 'box outline' : 'box',
    elevation !== undefined ? `el-${elevation}` : '',
    fullSize ? 'fullsize' : '',
    background ? 'background' : '',
    underline ? 'underline' : '',
    flex ? 'flex' : '',
    inlineFlex ? 'inline-flex' : '',
    ...hideOn.map(size => `hide-on-${size}`),
    className
  ]);

  if (margin && !style.margin) {
    style.margin = sizeHelper(margin);
  }

  if (padding && !style.padding) {
    style.padding = sizeHelper(padding);
  }

  if (xs || sm || md || lg) {
    return (
      <Grid item xs={xs} sm={sm} md={md} lg={lg}>
        <Component
          component={component}
          {...props}
          className={classNames}
          style={{ width, ...style }}
        >
          {children}
        </Component>
      </Grid>
    );
  }

  if(className.includes('scroll') || JSON.stringify(style).includes('scroll')) {
    style.paddingRight = 0;
  }

  return (
    <Component
      component={component}
      {...props}
      className={classNames}
      style={{ width, ...style }}
    >
      {children}
    </Component>
  );
};

export default forwardRef(Box);
