import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

// if (!firebase.app.length) {
  firebase.initializeApp({
    apiKey: 'AIzaSyB7uzWXthIb7ErFGGia_aJUz4EPmvIpSZY',
    authDomain: 'schillingroofbarhd.firebaseapp.com',
    databaseURL: 'https://schillingroofbarhd.firebaseio.com',
    projectId: 'schillingroofbarhd',
    storageBucket: 'schillingroofbarhd.appspot.com',
    messagingSenderId: '313577808081',
    appId: '1:313577808081:web:693fae11afa38a00e3a5f0',
    measurementId: 'G-7YFWFP94KR',
  });
// }

// if (typeof window !== 'undefined') {
//   firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
// }

export const db = firebase.firestore();

export default firebase;
