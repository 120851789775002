import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const GoogleIcon = (props: SvgIconProps) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.0001 10.2221C20.0001 9.39991 19.932 8.79991 19.7846 8.1777H10.2042V11.8887H15.8277C15.7144 12.811 15.1021 14.1999 13.7416 15.1331L13.7225 15.2574L16.7517 17.5571L16.9615 17.5776C18.8889 15.8332 20.0001 13.2665 20.0001 10.2221Z'
        fill='#4285F4'
      />
      <path
        d='M10.2041 19.9998C12.9591 19.9998 15.272 19.1109 16.9614 17.5776L13.7414 15.1332C12.8798 15.722 11.7233 16.1331 10.2041 16.1331C7.50572 16.1331 5.21551 14.3888 4.39911 11.9777L4.27945 11.9876L1.12969 14.3765L1.0885 14.4887C2.76647 17.7553 6.21316 19.9998 10.2041 19.9998Z'
        fill='#34A853'
      />
      <path
        d='M4.39901 11.9777C4.1836 11.3554 4.05893 10.6887 4.05893 9.99989C4.05893 9.31096 4.1836 8.64433 4.38768 8.02212L4.38197 7.8896L1.19274 5.46236L1.0884 5.511C0.396826 6.86656 0 8.38879 0 9.99989C0 11.611 0.396826 13.1331 1.0884 14.4887L4.39901 11.9777Z'
        fill='#FBBC05'
      />
      <path
        d='M10.204 3.86661C12.12 3.86661 13.4125 4.67771 14.1495 5.35552L17.0293 2.59998C15.2606 0.988884 12.959 0 10.204 0C6.21304 0 2.76635 2.24441 1.08838 5.51102L4.38766 8.02214C5.21539 5.61106 7.5056 3.86661 10.204 3.86661Z'
        fill='#EB4335'
      />
    </svg>
  );
};

export default GoogleIcon;
