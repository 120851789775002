import React, { useContext } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import './styles.scss';
import { AuthContext } from '../../../Contexts/AuthContext';

interface LoadingScreenProps {
  position?: 'fixed' | 'absolute';
  backgroundColor?: string;
}

const LoadingScreen = ({
  position = 'absolute',
  backgroundColor,
}: LoadingScreenProps) => {
  const { loading } = useContext(AuthContext);

  if (!loading) {
    return <></>;
  }

  return (
    <div className='loadingScreen' style={{ position, backgroundColor }}>
      <CircularProgress color='primary' />
    </div>
  );
};

export default LoadingScreen;
