import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { classHelper } from "../../../utils/helper";
import { Translations } from "../../../utils/types";

export type Colors = 'textPrimary' | 'primary' | 'secondary' | 'error' | 'subdued' | 'disabled' | 'tertiary' | 'inherit';
export type Variant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'text-1' | 'text-2' | 'text-3' | 'text-4' | 'text-5' | 'button';
export type Component = null | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span' | 'p' | 'label';
export interface TypographyProps {
  variant?: Variant;
  component?: Component;
  weight?: null | 'regular' | 'bold' | 'medium';
  textAlign?: 'left' | 'center' | 'right';
  color?: Colors;
  block?: boolean;
  caps?: boolean;
  className?: string;
  translation?: Translations|null;
  children?: any;
  style?: React.CSSProperties;
  onClick?:any;
  href?:string;
  target?:string;
  translationKey?:string|null;
}

const Typography = ({
  variant = "text-1",
  component = null,
  weight = null,
  color = "textPrimary",
  block = false,
  caps = false,
  className = "",
  textAlign = 'left',
  translation,
  translationKey = null,
  href,
  children,
  ...props
}:TypographyProps ) => {
  const classNames = classHelper([
    variant,
    color !== "inherit" ? "color-" + color : null,
    "align-" + textAlign,
    weight ? "weight-" + weight : null,
    caps ? "caps" : "",
    block ? "block" : "",
    className
  ]);

  const { t } = useTranslation(translation || undefined);

  const innerText = typeof children === "string" && !!translation && !translationKey ? t(children) : children;

  const text = translationKey ? <Trans i18nKey={translationKey}>{children}</Trans> : innerText;

  if (!!href) {
    return (
      <a {...props} href={href} className={classNames}>
        {text}
      </a>
    );
  }

  if (!component) {
    if (variant === "h1") {
      return (
        <h1 {...props} className={classNames}>
          {text}
        </h1>
      );
    } else if (variant === "h2") {
      return (
        <h2 {...props} className={classNames}>
          {text}
        </h2>
      );
    } else if (variant === "h3") {
      return (
        <h3 {...props} className={classNames}>
          {text}
        </h3>
      );
    } else if (variant === "h4") {
      return (
        <h4 {...props} className={classNames}>
          {text}
        </h4>
      );
    } else if (variant === "h5") {
      return (
        <h5 {...props} className={classNames}>
          {text}
        </h5>
      );
    } else if (variant === "h6") {
      return (
        <h6 {...props} className={classNames}>
          {text}
        </h6>
      );
    } else {
      return (
        <span {...props} className={classNames}>
          {text}
        </span>
      );
    }
  } else {
    if (component === "p") {
      return (
        <p {...props} className={classNames}>
          {text}
        </p>
      );
    } else if (component === "h1") {
      return (
        <h1 {...props} className={classNames}>
          {text}
        </h1>
      );
    } else if (component === "h2") {
      return (
        <h2 {...props} className={classNames}>
          {text}
        </h2>
      );
    } else if (component === "h3") {
      return (
        <h3 {...props} className={classNames}>
          {text}
        </h3>
      );
    } else if (component === "h4") {
      return (
        <h4 {...props} className={classNames}>
          {text}
        </h4>
      );
    } else if (component === "h5") {
      return (
        <h5 {...props} className={classNames}>
          {text}
        </h5>
      );
    } else if (component === "h6") {
      return (
        <h4 {...props} className={classNames}>
          {text}
        </h4>
      );
    } else if (component === "label") {
      return (
        <label {...props} className={classNames}>
          {text}
        </label>
      );
    }
  }
  return (
    <span {...props} className={classNames}>
      {text}
    </span>
  );
};

export default Typography;