import React from 'react';
import {
  default as MaterialTextField,
  TextFieldProps as MaterialTextFieldProps,
} from '@material-ui/core/TextField';
import { Size, Translations } from '../../../utils/types';
import { useTranslation } from 'react-i18next';
import { classHelper, sizeHelper } from '../../../utils/helper';

import './styles.scss';

type NewProps = {
  children?: React.ReactNode;
  label?: string;
  labelTranslation?: Translations;
  helperText?: string;
  helperTextTranslation?: Translations;
  errorMessage?: string;
  marginBottom?: Size;
  className?: string;
  paper?: boolean;
  rows?: number;
};

export type TextFieldProps = MaterialTextFieldProps & NewProps;

const TextField = ({
  label = '',
  labelTranslation = 'helpersAndErrors',
  helperText = '',
  helperTextTranslation= 'helpersAndErrors',
  errorMessage = '',
  style = {},
  className,
  paper = false,
  ...props
}: TextFieldProps) => {
  const { t } = useTranslation('helpersAndErrors');

  const classNames = classHelper([
    'text-field',
    paper ? 'text-field-paper' : '',
    className,
    !label ? 'small' : '',
  ]);

  return (
    <MaterialTextField
      variant='filled'
      {...props}
      size='small'
      color={props.color || 'primary'}
      className={classNames}
      label={t(label)}
      helperText={errorMessage ? t(errorMessage) : t(helperText)}
      error={!!errorMessage}
      style={{
        ...style,
        marginBottom: sizeHelper(props?.marginBottom ?? 'md'),
      }}
    />
  );
};

export default TextField;
