import React, { useContext, useState } from 'react';
import Box from '../../../Components/Atoms/Box';
import Button from '../../../Components/Atoms/Button';
import Typography from '../../../Components/Atoms/Typography';
import Card from '../../../Components/Molecules/Card';
import EmailForm from '../../Components/EmailForm/EmailForm';
import NavigationBar from '../../Components/NavigationBar/NavigationBar';
import { AuthContext } from '../../../Contexts/AuthContext';
import {
  FacebookIcon,
  GoogleIcon,
  Mail,
} from '../../../Components/Atoms/Icons';

import './styles.scss';

export type SignUpProps = {
  invitationId: string;
  navigate: any;
};

const SignUp = ({ invitationId, navigate }: SignUpProps) => {
  const [emailOpen, setemailOpen] = useState<boolean>(false);
  const [emailMethod, setemailMethod] = useState<'signUp' | 'signIn'>('signUp');
  const { signUpWaiter, invitationState, setinvitationState } = useContext(
    AuthContext
  );

  const handleClick = (
    method: 'email' | 'google' | 'facebook' | null
  ) => async () => {
    try {
      const { error }: any = await signUpWaiter({ method });

      if (error) throw error;

      navigate(`/${invitationId}/sendRequest`);
    } catch (error: any) {
      console.error(error);
      setinvitationState({ ...invitationState, error: error.message });
    }
  };

  const handleEmailClick = () => setemailOpen(!emailOpen);

  const handleSubmit = async ({ email = '', password = '', name = '' }) => {
    const { error }: any = await signUpWaiter({
      method: 'email',
      formData: { email, password, name },
      emailMethod: emailMethod,
    });

    if (error) {
      setinvitationState({ ...invitationState, error: error.message });
      return;
    }

    navigate(`/${invitationId}/waitingForAcceptance`);

    return;
  };

  return (
    <Box flex>
      <NavigationBar />
      <Box id='signup-screen'>
        <Card
          className='welcome-card'
          title='Welcome to Gastronaut'
          typoVariantTitle='h3'
          typoVariantDescription='text-3'
          titleTranslation='common'
        >
          <Typography color='subdued' translation='common' variant='text-3'>
            You have been invited by
          </Typography>{' '}
          <Typography variant='text-3' weight='bold'>
            {invitationState.restaurantName}
          </Typography>{' '}
          <Typography color='subdued' translation='common' variant='text-3'>
            to use Gastronaut.
          </Typography>
        </Card>

        <div className='signup-procedure'>
          <Box className='signup-btns'>
            <Button
              translation='common'
              startIcon={() => <GoogleIcon />}
              variant='outlined'
              className='btn google'
              fullWidth
              onClick={handleClick('google')}
              typographyProps={{ variant: 'text-2' }}
            >
              Sign up with Google
            </Button>
            <Button
              translation='common'
              startIcon={() => <FacebookIcon />}
              variant='outlined'
              className='btn facebook'
              fullWidth
              onClick={handleClick('facebook')}
              typographyProps={{ variant: 'text-2' }}
            >
              Sign up with Facebook
            </Button>
          </Box>
          <Box className='or-divider' flex>
            <div className='dividers'></div>
            <Typography
              color='subdued'
              block
              variant='text-2'
              className='or-text'
            >
              or
            </Typography>
            <div className='dividers'></div>
          </Box>
          {!emailOpen ? (
            <Button
              translation='common'
              className='btn email'
              startIcon={() => <Mail />}
              fullWidth
              onClick={handleEmailClick}
              variant='outlined'
              typographyProps={{ variant: 'text-2' }}
            >
              Sign up with Email
            </Button>
          ) : (
            <EmailForm
              goBack={() => {
                setemailOpen(false);
                setinvitationState((st) => ({ ...st, error: null }));
              }}
              handleSubmit={handleSubmit}
              error={invitationState.error}
              setemailMethod={setemailMethod}
              emailMethod={emailMethod}
              invitationId={invitationId}
            />
          )}
        </div>
        <Box fullSize className='login-now'>
          <Typography translation='common' variant='text-2'>
            Already have an account?
          </Typography>{' '}
          <Typography
            translation='common'
            color='secondary'
            variant='text-2'
            href='https://app.gastronaut.ai/signin'
          >
            Login now
          </Typography>
        </Box>
      </Box>
      <Box
        className='flex-grow'
        hideOn={['md']}
        style={{
          height: 'calc(100vh - -65px)',
          maxWidth: '40%',
          maxHeight: '100vh',
          backgroundImage: 'url("/rest-illustration.png")',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      />
    </Box>
  );
};

export default SignUp;
