import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const FacebookIcon = (props: SvgIconProps) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20 9.99999C20 4.47714 15.5229 -8.17969e-06 10 -8.66252e-06C4.47715 -9.14534e-06 1.34643e-06 4.47714 8.63607e-07 9.99999C4.27255e-07 14.9913 3.65686 19.1283 8.4375 19.8785L8.4375 12.8906L5.89844 12.8906L5.89844 9.99999L8.4375 9.99999L8.4375 7.79687C8.4375 5.29062 9.93043 3.90624 12.2146 3.90624C13.3087 3.90624 14.4531 4.10155 14.4531 4.10155L14.4531 6.56249L13.1921 6.56249C11.9499 6.56249 11.5625 7.33333 11.5625 8.12415L11.5625 9.99999L14.3359 9.99999L13.8926 12.8906L11.5625 12.8906L11.5625 19.8785C16.3431 19.1283 20 14.9913 20 9.99999Z'
        fill='#1877F2'
      />
      <path
        d='M13.8926 12.8906L14.3359 10L11.5625 10L11.5625 8.12416C11.5625 7.33334 11.9499 6.5625 13.1921 6.5625L14.4531 6.5625L14.4531 4.10157C14.4531 4.10157 13.3087 3.90625 12.2146 3.90625C9.93043 3.90625 8.4375 5.29063 8.4375 7.79688L8.4375 10L5.89844 10L5.89844 12.8906L8.4375 12.8906L8.4375 19.8785C8.94662 19.9584 9.46844 20 10 20C10.5316 20 11.0534 19.9584 11.5625 19.8785L11.5625 12.8906L13.8926 12.8906Z'
        fill='white'
      />
    </svg>
  );
};

export default FacebookIcon;
