import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowBack,
  WarningRounded,
  ExitToAppRounded,
} from '../../../Components/Atoms/Icons';
import { AuthContext } from '../../../Contexts/AuthContext';
import Box from '../../../Components/Atoms/Box';
import Button from '../../../Components/Atoms/Button';
import Typography from '../../../Components/Atoms/Typography';
import NavigationBar from '../../Components/NavigationBar/NavigationBar';

import './styles.scss';

const ErrorPage = () => {
  const { invitationId } = useParams();
  const { invitationState, signOutUser } = useContext(AuthContext);
  const navigate: any = useNavigate();

  if (invitationState.new) {
    navigate(`/${invitationId}`);
  }

  const handleGoBackClick = () => navigate(-1);

  return (
    <Box flex>
      <NavigationBar />
      <div id='error-page-screen' className='flex-column'>
        <WarningRounded className='warning' />
        {invitationState.error ? (
          <Typography translation='helpersAndErrors' color='error'>
            Error: {invitationState.error}
          </Typography>
        ) : (
          <Typography translation='helpersAndErrors' color='error'>
            An error occured. Please try again later.
          </Typography>
        )}
        <Box className='btns'>
          <Button
            variant='transparent'
            onClick={handleGoBackClick}
            startIcon={() => <ArrowBack />}
          >
            Go back
          </Button>
          <Button
            variant='transparent'
            onClick={signOutUser}
            startIcon={() => <ExitToAppRounded />}
          >
            Sign Out
          </Button>
        </Box>
      </div>
      <Box
        className='flex-grow'
        hideOn={['md']}
        style={{
          height: 'calc(100vh - -65px)',
          maxWidth: '40%',
          backgroundImage: 'url("/rest-illustration.png")',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      />
    </Box>
  );
};

export default ErrorPage;
