import React from 'react';
import {
  default as MaterialInputLabel,
  InputLabelProps as MaterialInputLabelProps,
} from '@material-ui/core/InputLabel';
import './styles.scss';
import { Translations } from '../../../utils/types';
import { useTranslation } from 'react-i18next';
import { classHelper } from '../../../utils/helper';

type NewProps = {
  children?: string;
  labelTranslation?: Translations;
};

export type InputLabelProps = MaterialInputLabelProps & NewProps;

const InputLabel = ({
  children = '',
  labelTranslation = 'common',
  ...props
}: InputLabelProps) => {
  const { t } = useTranslation(classHelper(['errors', labelTranslation]));

  return (
    <MaterialInputLabel
      children={t(children)}
      className='input-label'
      {...props}
    />
  );
};

export default InputLabel;
