import React, { createContext, Suspense, useEffect, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import de from '../translations/de';
import en from '../translations/en';
import fr from '../translations/fr';

type Language = {
  id: string;
  label: string;
};

interface Props {
  language?: string;
  children: any;
}

export const LANGUAGES: Language[] = [
  {
    id: 'de',
    label: 'Deutsch',
  },
  {
    id: 'en',
    label: 'English',
  },
  {
    id: 'fr',
    label: 'Français',
  },
];

i18next.init({
  resources: {
    en,
    de,
    fr,
  },
  interpolation: { escapeValue: false },
  fallbackLng: 'en',
  lng: 'de',
});

export const LanguageContext = createContext({
  language: 'de',
  LANGUAGES,
  changeLanguage: (id: string) => {},
});

const LanguageContextProvider = (props: Props) => {
  const [language, setlanguage] = useState('de');

  useEffect(() => {
    changeLanguage(props.language || '');
  }, [props.language]);

  const changeLanguage = (id: string) => {
    if (LANGUAGES.find((l) => l.id === id)) {
      setlanguage(id);
      i18next.changeLanguage(id);
    }
  };

  return (
    <Suspense fallback='Loading...'>
      <LanguageContext.Provider value={{ language, LANGUAGES, changeLanguage }}>
        <I18nextProvider i18n={i18next}>{props.children}</I18nextProvider>
      </LanguageContext.Provider>
    </Suspense>
  );
};

export default LanguageContextProvider;
