import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '../../../Components/Atoms/Box';
import { DesktopImg } from '../../../Components/Atoms/Icons';
import Typography from '../../../Components/Atoms/Typography';
import Card from '../../../Components/Molecules/Card';
import { AuthContext } from '../../../Contexts/AuthContext';
import NavigationBar from '../../Components/NavigationBar/NavigationBar';

import './styles.scss';

const PendingInvit = () => {
  const { invitationId } = useParams();
  const { invitationState } = useContext(AuthContext);
  const navigate: any = useNavigate();

  if (invitationState.new) {
    navigate(`/${invitationId}`);
  }

  return (
    <Box flex>
      <NavigationBar />
      <div id='pending-invit-screen' className='flex-column'>
        <Card
          titleTranslation='common'
          className='pending-card'
          title='Waiting for Approval'
          titleAlignment='center'
          typoVariantTitle='h2'
          typoVariantDescription='text-2'
        >
          It seems like you have not been approved yet by the Restaurant Staff.
          They can do so inside Settings &gt; Users &gt; Invitations.
        </Card>
         <DesktopImg />
{/*        <Typography
          translation='common'
          className='or-divider'
          color='subdued'
          variant='text-3'
        >
          or
        </Typography>
        <Typography translation='common' variant='h4'>
          Download our app
        </Typography>

        <div className='download-badges'>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://play.google.com/store/apps'
          >
            <img src='/googleplay.png' alt='download in google play badge' />
          </a>

          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://www.apple.com/de/app-store/'
          >
            <img src='/appstore.png' alt='download in appstore badge' />
          </a>
        </div> */}
      </div>
      <Box
        className='flex-grow'
        hideOn={['md']}
        style={{
          height: 'calc(100vh - -65px)',
          maxWidth: '40%',
          maxHeight: '100vh',
          backgroundImage: 'url("/rest-illustration.png")',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      />
    </Box>
  );
};

export default PendingInvit;
