import { FormHelperText, IconButton } from '@material-ui/core';
import React from 'react';
import { Visibility, VisibilityOff } from '../../Atoms/Icons';
import InputAdornment from '../../Atoms/InputAdornment';
import Input from '../../Atoms/Input';
import InputLabel from '../../Atoms/InputLabel';
import FormControl from '../../Atoms/FormControl';
import './styles.scss';

export type ShowPwdTextfieldProps = {
  state: {
    errors: Record<string, string | null>;
    informations: Record<string, undefined | string>;
  };
  onChange: (target: { name: string; value: string }) => void;
  onBlur: (target: { name: string; value: string }) => boolean;
  showPwd: boolean;
  setshowPwd: React.Dispatch<React.SetStateAction<boolean>>;
};

const ShowPwdTextfield = ({
  state,
  onChange,
  onBlur,
  showPwd,
  setshowPwd,
}: ShowPwdTextfieldProps) => {
  return (
    <>
      <FormControl className='formcontrol-showpwd' fullWidth>
        <InputLabel
          className='input- MuiInputLabel-marginDense MuiInputLabel-filled'
          htmlFor='pwd'
        >
          Password
        </InputLabel>
        <Input
          id='pwd'
          className='textfield'
          name='password'
          value={state.informations.password ? state.informations.password : ''}
          onChange={(e) => onChange(e.target)}
          fullWidth
          onBlur={(
            e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
          ) => onBlur({ name: e.target.name, value: e.target.value })}
          error={!!state.errors.password}
          helperText={
            typeof state.errors.password === 'string'
              ? state.errors.password
              : ''
          }
          style={!!state.errors.password ? { marginBottom: 0 } : {}}
          type={showPwd ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={() => setshowPwd(true)}
                onMouseDown={() => setshowPwd(false)}
                edge='end'
              >
                {showPwd ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        {!!state.errors.password && (
          <FormHelperText style={{ margin: '4px 14px 0' }}>
            {state.errors.password}
          </FormHelperText>
        )}
      </FormControl>
    </>
  );
};

export default ShowPwdTextfield;
