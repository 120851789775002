import './App.scss';
import AuthContextProvider from './Contexts/AuthContext';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import InvitationContainer from './App/InvitationContainer';
import SendRequest from './App/SendRequest/Screen/SendRequest';
import ErrorPage from './App/ErrorPage/Screen/ErrorPage';
import PendingInvit from './App/Done/Screen/PendingInvit';
import ThemeContextProvider from './Contexts/ThemeContext';
import LanguageContextProvider from './Contexts/LanguageContext';
import EmptyRoute from './App/Empty Route/Screen/EmptyRoute';
import LoadingScreen from './Components/Atoms/LoadingScreen';

function App() {
  return (
    <div className='App'>
      <BrowserRouter>
        <ThemeContextProvider>
          <AuthContextProvider>
            <LanguageContextProvider>
              <Routes>
                <Route
                  path='/:invitationId'
                  element={<InvitationContainer />}
                />
                <Route
                  path='/:invitationId/sendRequest'
                  element={<SendRequest />}
                />
                <Route
                  path='/:invitationId/waitingForAcceptance'
                  element={<PendingInvit />}
                />
                <Route path='/:invitationId/error' element={<ErrorPage />} />
                <Route path='/' element={<EmptyRoute />} />
              </Routes>
              <LoadingScreen />
            </LanguageContextProvider>
          </AuthContextProvider>
        </ThemeContextProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
