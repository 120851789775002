import React, { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../Contexts/AuthContext';
import SignUp from './SignUp/Screen/SignUp';

const InvitationContainer = () => {
  const { invitationId } = useParams();
  const { init, invitationState } = useContext(AuthContext);
  const navigate: any = useNavigate();

  /* eslint-disable */
  useEffect(() => {
    invitationId && init(invitationId);
  }, [invitationId]);
  /* eslint-disable */


  if (invitationState.error) {
    // console.log(invitationState);
    navigate(`/${invitationId}/error`);
  }

  const userHasAccess: boolean = invitationState.error === 'userHasAccess';
  const invitationSuccess: boolean =
    invitationState.error === 'invitationSuccess';

  if (userHasAccess || invitationSuccess) {
    location.replace('https://app.gastronaut.ai');
  }

  const invitationSend: boolean = invitationState.error === 'invitationSend';

  if (invitationSend) {
    navigate(`/${invitationId}/waitingForAcceptance`);
  }

  if (invitationState.user) {
    navigate(`/${invitationId}/sendRequest`);
  }

  return (
    <>{invitationId ? <SignUp {...{ invitationId, navigate }} /> : <></>}</>
  );
};

export default InvitationContainer;
