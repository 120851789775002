import Typography, { TypographyProps, Colors } from '../../Atoms/Typography';
import ButtonBase, { ButtonBaseProps } from '@material-ui/core/ButtonBase';
import React from 'react';
import { classHelper } from '../../../utils/helper';
import './styles.scss';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Translations, Modify } from '../../../utils/types';

type IconFunction = {
  (params: object): React.ReactNode;
};

export type ButtonVariants =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'transparent'
  | 'outlined';

type LinkTo = {
  (rId: string): string;
};

export type ButtonProps = Modify<
  ButtonBaseProps,
  {
    className?: string;
    variant?: ButtonVariants;
    endIcon?: null | IconFunction;
    linkTo?: LinkTo | string;
    startIcon?: null | IconFunction;
    disabled?: boolean;
    onClick?: React.ReactEventHandler;
    fullWidth?: boolean;
    children?: React.ReactNode;
    translation?: Translations;
    flat?: boolean;
    loading?: boolean;
    typographyProps?: TypographyProps;
    color?: Colors;
    href?: string;
    target?: string;
  }
>;

const iconParams = {
  fontSize: 'inherit',
  color: 'inherit',
};

const Button = ({
  variant = 'default',
  linkTo,
  children,
  fullWidth = false,
  startIcon = null,
  endIcon = null,
  className = '',
  disabled = false,
  flat = false,
  loading = false,
  typographyProps = {},
  color = 'textPrimary',
  translation = 'common',
  ...props
}: ButtonProps) => {
  const classNames = classHelper([
    'btn',
    `btn-${variant}`,
    startIcon || endIcon ? 'btn-with-icon' : '',
    fullWidth ? 'btn-full-width' : '',
    flat ? 'btn-flat' : '',
    className,
    color && !disabled ? `color-${color}` : '',
    loading ? 'btn-loading' : '',
    disabled && !loading ? 'btn-disabled' : '',
  ]);

  return (
    <ButtonBase
      {...props}
      style={{ padding: '0.5rem 1rem', borderRadius: '0.25rem' }}
      className={classNames}
      disabled={disabled}
      // onClick={onClick}
    >
      <span className='btn-inner'>
        {!!startIcon &&
          startIcon({ ...iconParams, className: 'btn-start-icon' })}
        <Typography
          variant='button'
          color={disabled ? 'disabled' : color}
          {...typographyProps}
          translation={translation}
        >
          {children}
        </Typography>
        {!!endIcon && endIcon({ ...iconParams, className: 'btn-end-icon' })}
      </span>
      {loading && <CircularProgress color='inherit' size={16} />}
    </ButtonBase>
  );
};

export default Button;
