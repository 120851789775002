import React, { useContext } from 'react';
import Box from '../../../Components/Atoms/Box';
import Button from '../../../Components/Atoms/Button';
import { Brightness4 } from '../../../Components/Atoms/Icons';
import Typography from '../../../Components/Atoms/Typography';
import Select from '../../../Components/Molecules/Select';
import { LanguageContext } from '../../../Contexts/LanguageContext';
import { ThemeContext } from '../../../Contexts/ThemeContext';
import './styles.scss';

const NavigationBar = () => {
  const { language, changeLanguage, LANGUAGES } = useContext(LanguageContext);
  const { darkMode, setdarkMode } = useContext(ThemeContext);

  const handleChange = (e: any) => {
    changeLanguage(e.target.value.toLowerCase());
  };

  return (
    <Box flex id='navbar'>
      <div className='logo-and-gastronaut'>
        <img src='/logo192.png' alt='gastronaut logo' />
        <Typography variant='h4'>Gastronaut</Typography>
      </div>

      <div className='darkmode-and-lang'>
        <Button
          variant='transparent'
          startIcon={() => <Brightness4 />}
          onClick={() => (!darkMode ? setdarkMode(true) : setdarkMode(false))}
        />

        <Select
          className='language-selection'
          value={language.toLocaleUpperCase()}
          onChange={(e: any) => handleChange(e)}
          options={LANGUAGES.map((l) => l.id.toLocaleUpperCase())}
        />
      </div>
    </Box>
  );
};

export default NavigationBar;
