import React from 'react';
import {
  default as MaterialFormControl,
  FormControlProps as MaterialFormControlProps,
} from '@material-ui/core/FormControl';
import './styles.scss';

export type FormControlProps = MaterialFormControlProps;

const FormControl: React.FC<FormControlProps> = ({ ...props }) => {
  return (
    <>
      <MaterialFormControl {...props} />
    </>
  );
};

export default FormControl;
