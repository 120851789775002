import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const DesktopImg = (props: SvgIconProps) => {
  return (
    <svg
      width='265'
      height='136'
      viewBox='0 0 265 136'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.12964 63.4999C1.22594 63.2346 -11.1439 117.713 35.8648 124.81C35.8648 124.81 49.6938 77.503 2.12964 63.4999Z'
        fill='#10CCB9'
      />
      <path
        d='M12.2399 81.4849L35.197 124.141'
        stroke='white'
        stroke-width='2'
        stroke-miterlimit='10'
        stroke-linecap='round'
      />
      <path
        d='M11.0944 94.7882L21.9636 99.5305'
        stroke='white'
        stroke-width='2'
        stroke-miterlimit='10'
        stroke-linecap='round'
      />
      <path
        d='M30.8567 96.3414L27.872 110.519'
        stroke='white'
        stroke-width='2'
        stroke-miterlimit='10'
        stroke-linecap='round'
      />
      <path
        d='M148.959 105.069H127.162V125.075H148.959V105.069Z'
        fill='#E2E8F0'
      />
      <path
        d='M156.122 124.137H120.298C119.311 124.137 118.507 124.941 118.507 125.928C118.507 126.914 119.311 127.719 120.298 127.719H156.122C157.109 127.719 157.913 126.914 157.913 125.928C157.913 124.941 157.109 124.137 156.122 124.137Z'
        fill='#E2E8F0'
      />
      <path
        d='M226.679 117.621H49.5885C47.7811 117.621 46.3219 116.162 46.3219 114.355V3.26655C46.3219 1.45917 47.7811 0 49.5885 0H226.679C228.487 0 229.946 1.45917 229.946 3.26655V114.355C229.946 116.162 228.487 117.621 226.679 117.621Z'
        fill='#E2E8F0'
      />
      <path
        d='M51.1179 114.172C50.6951 114.172 50.3469 113.824 50.3469 113.401V4.22784C50.3469 3.80501 50.6951 3.4568 51.1179 3.4568H225.158C225.581 3.4568 225.929 3.80501 225.929 4.22784V113.401C225.929 113.824 225.581 114.172 225.158 114.172H51.1179Z'
        fill='white'
      />
      <path
        d='M87.9078 16.8339H66.442V38.2997H87.9078V16.8339Z'
        fill='#EDF2F7'
      />
      <path
        d='M209.547 81.2331H171.726V99.6323H209.547V81.2331Z'
        fill='#EDF2F7'
      />
      <path
        d='M87.9078 51.5754H66.442V72.0189H87.9078V51.5754Z'
        fill='#EDF2F7'
      />
      <path
        d='M147.19 51.5754H125.724V72.0189H147.19V51.5754Z'
        fill='#EDF2F7'
      />
      <path
        d='M117.552 51.5754H96.0859V72.0189H117.552V51.5754Z'
        fill='#EDF2F7'
      />
      <path
        d='M176.838 51.5754H155.372V72.0189H176.838V51.5754Z'
        fill='#EDF2F7'
      />
      <path
        d='M206.479 51.5754H185.013V72.0189H206.479V51.5754Z'
        fill='#EDF2F7'
      />
      <path
        d='M165.593 81.2331H64.3973V99.6323H165.593V81.2331Z'
        fill='#EDF2F7'
      />
      <path
        d='M206.478 30.1207H95.0603V35.2316H206.478V30.1207Z'
        fill='#EDF2F7'
      />
      <path
        d='M125.726 18.8685H95.0603V23.9794H125.726V18.8685Z'
        fill='#E2E8F0'
      />
      <path
        d='M195.235 18.8685H132.882V23.9794H195.235V18.8685Z'
        fill='#EDF2F7'
      />
      <path d='M264.965 127.717H0V136H264.965V127.717Z' fill='#CBD5E0' />
      <path
        d='M260.733 80.9718C261.38 80.7812 270.185 119.582 236.699 124.639C236.707 124.639 226.857 90.9456 260.733 80.9718Z'
        fill='#10CCB9'
      />
      <path
        d='M236.473 125.93L254.704 91.7386'
        stroke='white'
        stroke-width='2'
        stroke-miterlimit='10'
        stroke-linecap='round'
      />
      <path
        d='M245.591 96.8353L246.495 107.149'
        stroke='white'
        stroke-width='2'
        stroke-miterlimit='10'
        stroke-linecap='round'
      />
      <path
        d='M242.893 113.884C242.802 114.059 251.375 110.353 251.375 110.353L242.893 113.884Z'
        fill='white'
      />
      <path
        d='M242.893 113.884C242.802 114.059 251.375 110.353 251.375 110.353'
        stroke='white'
        stroke-width='2'
        stroke-miterlimit='10'
        stroke-linecap='round'
      />
    </svg>
  );
};

export default DesktopImg;
