import React, { createContext, useState, useEffect } from 'react';
import firebase, { db } from '../utils/firebase';

export enum UserRoles {
  WAITER = 'waiter',
  CHEFWAITER = 'chefWaiter',
  TABLET = 'tablet',
  ADMIN = 'admin',
}

export type Products =
  | 'general'
  | 'delivery'
  | 'vouchers'
  | 'merchandise'
  | 'followUp'
  | 'menu'
  | 'feedback'
  | 'reservation';

export type Restaurant = {
  id: string;
  name?: string;
  admin: boolean;
  role?: string;
  title?: string;
  products: Products[];
};

export interface UserData {
  displayName: string | null;
  isGastronautAdmin?: boolean;
  email: string | null;
  emailVerified: boolean;
  photoURL: string | null;
  isAnonymous: boolean;
  uid: string;
  role: UserRoles;
  restaurants: Restaurant[];
}

interface UserType {
  user: UserData | null | undefined;
  signInError: false | string;
}

export type ProviderDataProps = {
  email: string;
  password: string;
};

export type FormDataType = {
  email: string;
  password: string;
  name: string;
};

export type SignUpWaiterType = {
  method?: 'email' | 'google' | 'facebook' | null;
  formData?: FormDataType;
  emailMethod?: 'signUp' | 'signIn' | null;
};

export type AuthContextType = {
  db: firebase.firestore.Firestore;
  invitationState: {
    new?: boolean;
    loading: boolean;
    error: null | string;
    uid: string | null;
    displayName?: string | null;
    restaurantId?: string | null;
    restaurantName?: string | null;
    user?: firebase.User | null;
  };
  setinvitationState: React.Dispatch<
    React.SetStateAction<{
      new?: boolean;
      loading: boolean;
      error: null | string;
      uid: string | null;
      displayName?: string | null;
      restaurantId?: string | null;
      restaurantName?: string | null;
      user?: firebase.User | null;
    }>
  >;
  signInUser: (email: string, password: string) => void;
  signOutUser: () => void;
  signUpWaiter: ({
    method,
    formData,
    emailMethod,
  }: SignUpWaiterType) => Promise<
    void | { error: any } | { error?: undefined }
  >;
  init: (invitationId: string) => Promise<void>;
  loading: boolean;
  sendInvitation: (name: string) => Promise<void | {}>;
};

export const AuthContext = createContext<AuthContextType>({
  db,
  invitationState: { new: true, loading: true, error: null, uid: null },
  setinvitationState: () => {},
  signInUser: () => {},
  signOutUser: () => {},
  signUpWaiter: async () => {},
  init: async () => {},
  loading: true,
  sendInvitation: async () => {},
});

const AuthContextProvider = ({ children }: any) => {
  const [data, setdata] = useState<UserType>({
    user: undefined,
    signInError: false,
  });
  const [invitationState, setinvitationState] = useState<{
    loading: boolean;
    error: null | string;
    uid: string | null;
    new?: boolean;
    displayName?: string | null;
    restaurantId?: string | null;
    restaurantName?: string | null;
    user?: firebase.User | null;
  }>({
    new: true,
    loading: true,
    error: null,
    uid: null,
  });

  // console.log('invSt', invitationState);

  const signInUser = (email: string, password: string) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch(function (error) {
        // Handle Errors here.
        // var errorCode = error.code;
        let errorMessage = error.message;
        // ...
        // console.log(errorCode, errorMessage)
        setdata({ ...data, signInError: errorMessage });
      });
  };

  const signOutUser = () => {
    console.log('signout');
    try {
      firebase
        .auth()
        .signOut()
        .then(function () {
          setdata({ ...data, user: null });
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (data.user === undefined) {
      const user = firebase.auth().currentUser;

      if (user) {
      }
    }
  }, [data.user]);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        setinvitationState((i) => ({
          ...i,
          user: user,
          uid: user.uid,
        }));
      } else {
        console.log('No user was detected');
      }
    });
  }, []);

  const checkUser = async (uid: string, restaurantId: string) => {
    const userDoc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData> = await db
      .collection('users')
      .doc(uid)
      .get();

    if (
      userDoc.exists &&
      userDoc.data()?.restaurants.find((r: Restaurant) => r.id === restaurantId)
    ) {
      throw new Error('userHasAccess');
    }

    const invitationDoc = await db
      .collection(`restaurants/${restaurantId}/pendingInvitations`)
      .doc(uid)
      .get();

    if (invitationDoc.exists) {
      let status = invitationDoc.data()?.status;

      if (status === 'pending') {
        throw new Error('invitationSend');
      } else if (status === 'failed') {
        throw new Error('Your request was declined by the restaurant');
      } else {
        throw new Error('invitationSuccess');
      }
    }

    return;
  };

  const signUpWaiter = async ({
    method = null,
    formData = { email: '', password: '', name: '' },
    emailMethod = null,
  }: SignUpWaiterType) => {
    // console.log('meth', method, '//emailMethod', emailMethod);
    try {
      if (!method) throw new Error('Choose a Method');

      const restaurantId = invitationState.restaurantId;

      if (method === 'email') {
        let { email, password, name } = formData;

        let uid: string | null = null;

        if (emailMethod === 'signUp') {
          const { user } = await firebase
            .auth()
            .createUserWithEmailAndPassword(email, password);

          uid = user?.uid ? user.uid : null;
        } else {
          try {
            const { user } = await firebase
              .auth()
              .signInWithEmailAndPassword(email, password);

            uid = user?.uid ? user.uid : null;
          } catch (error: any) {
            if (error.code === 'auth/wrong-password') {
              return { error };
            } else {
              throw error;
            }
          }
          await checkUser(uid ? uid : '', restaurantId ? restaurantId : '');
        }

        if (uid) {
          await db
            .collection(`restaurants/${restaurantId}/pendingInvitations`)
            .doc(uid)
            .set({ name, status: 'pending', createdAt: Date.now() });

          setinvitationState((cV) => ({ ...cV, uid, displayName: name }));

          return {};
        } else {
          throw new Error('No uid in SignUp func');
        }
      } else {
        let provider: ProviderDataProps | any = null;

        switch (method) {
          case 'google':
            provider = new firebase.auth.GoogleAuthProvider();
            break;
          case 'facebook':
            provider = new firebase.auth.FacebookAuthProvider();
            break;
          default:
            break;
        }

        const { user } = await firebase.auth().signInWithPopup(provider);

        if (restaurantId && user?.uid) {
          await checkUser(user.uid, restaurantId);
        } else {
          throw new Error('No rest id');
        }

        setinvitationState((cV) => ({
          ...cV,
          uid: user.uid,
          displayName: restaurantId,
        }));

        return {};
      }
    } catch (error) {
      console.error(error);
      return { error };
    }
  };

  const init = async (invitationId: string) => {
    try {
      const ref = db.collection('waiterInvitation').doc(invitationId);

      const doc = await ref.get();

      if (!doc.exists) throw new Error('Wrong Link');

      const restaurantId: string = doc.data()?.restaurantId;
      const restaurantName: string = doc.data()?.restaurantName;

      let user = firebase.auth().currentUser;

      if (user) {
        await checkUser(user.uid, restaurantId);
      }

      setinvitationState({
        restaurantId,
        restaurantName,
        loading: false,
        error: null,
        user,
        uid: user?.uid ? user.uid : null,
      });
    } catch (error: any) {
      console.error(error.message);
      setinvitationState({
        loading: false,
        error: error.message,
        uid: null,
      });
    }
  };

  const sendInvitation = async (name: string) => {
    try {
      const { uid, restaurantId } = invitationState;

      if (uid) {
        await db
          .collection(`restaurants/${restaurantId}/pendingInvitations`)
          .doc(uid)
          .set({ name, status: 'pending', createdAt: Date.now() });

        return {};
      } else {
        console.log('No uid detected');
        let error: string = 'No uid detected';
        return { error };
      }
    } catch (error: any) {
      return { error };
    }
  };

  return (
    <AuthContext.Provider
      value={{
        db,
        invitationState,
        setinvitationState,
        signInUser,
        signOutUser,
        signUpWaiter,
        init,
        loading: invitationState.loading,
        sendInvitation,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
